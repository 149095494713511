body {
    background-color: #ededed;
    scroll-behavior:smooth;
}

#workExp {
    scroll-margin-top: 80px;
}
#skills {
    scroll-margin-top: 96px;
}

.cover {
    display: grid;
    grid-template-columns: 1.2fr 1fr; 
    height: 100vh;
}

.left {
    position: fixed;
    max-width: 40vw;
    background-color: white;
    border-radius: 1rem;
    margin: 7% 7%;
    padding: 3rem 3rem;
    box-shadow: 0 2px 4px rgba(95, 95, 95, 0.1);
}

.dp {
    content: "";
    position: absolute;
    top: -10%;
    left: 10%;
    width: 30%;
    height: 25%;
    border-radius: 1rem;
    border: solid rgba(63, 63, 63, 0.1);
    background-color: white;
}

.get-in-touch{
    display: flex;
    justify-content: flex-end;
    margin: 0rem 1rem
}
.right {
    margin-right: 16%;
    scrollbar-width: none; 
}

.active {
    color: #007BFF;
    font-weight: 800;
    font-size: larger;
}

